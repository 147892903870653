import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

import Img from 'gatsby-image'
import { Link } from 'gatsby'

import standardArticleStyle from './standardArticleStyle.jsx'

import classNames from 'classnames'
import ReactRevealFadeIn from '../ReactRevealFadeIn/ReactRevealFadeIn'


const StandardArticle = props => {


  const { classes, artikel } = props


  const imgClasses = classNames({
    [classes.bigImage]: true,
    [classes.imageDarkenOnHover]: true,
    // [classes.bigImageCustom]: props.textUnterBildAnzeigen,
  })
  const mainWrapper = classNames({
    [classes.mainWrapper]: true,
    // [classes.mainWrapperCustom]: props.textUnterBildAnzeigen,
  })
  const subWrapper = classNames({
    [classes.subWrapper]: true,
    // [classes.subWrapperCustom]: props.textUnterBildAnzeigen,
  })
  const firstWrapper = classNames({
    [classes.firstWrapper]: true,
    // [classes.firstWrapperCustom]: props.textUnterBildAnzeigen,
  })
  const secondWrapper = classNames({
    [classes.secondWrapper]: true,
    // [classes.secondWrapperCustom]: props.textUnterBildAnzeigen,
  })

  return (
    <ReactRevealFadeIn>
    <div className={mainWrapper}>
    <div className={subWrapper}>
      <div className={firstWrapper}>
        <Link to={'blog/' + artikel.slug} style={{ width: '100%' }}>
          <Img
            className={imgClasses}

            fluid={artikel.artikelbild.fluid}
            alt={artikel.artikelbild.alt}
            backgroundColor={'#f2f2f2'}
            objectFit="contain"
            objectPosition="50% 50%"
          />
        </Link>

      </div>
      <div className={secondWrapper}>
        <div className={classes.smallArticleContent}>
          <Link to={'blog/' + artikel.slug}>
            <h4 className={classes.smallTitle}>
              {artikel.titel}
            </h4>
            <p className={classes.P}>
              {artikel.inhaltsausschnitt ?
                artikel.inhaltsausschnitt.length > 140 ? artikel.inhaltsausschnitt.slice(0, 140) + '...' : artikel.inhaltsausschnitt
                : null}

            </p>
          </Link>

          <Link to={'blog/' + artikel.slug}>
            <p className={classes.smallDate}>{artikel.meta.updatedAt}</p>
          </Link>
        </div>
      </div>
    </div>
    </div>
    </ReactRevealFadeIn>
  )

}

export default withStyles(standardArticleStyle)(StandardArticle)
