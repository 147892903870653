import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

import Header from 'components/Header/Header.jsx'
import Footer from 'components/Footer/Footer.jsx'


import { graphql, useStaticQuery } from 'gatsby'
import PageHeader from '../../components/PageHeader/PageHeader'
import InstagramSection from '../../components/InstagramSection/InstagramSection'
import SpainHolidaysCta from '../../elements/SpainholidaysCta/SpainHolidaysCta'
import BlogMain from '../../components/BlogMain/BlogMain'


const styles = {
  root: {},
}

const Blog = props => {
  const data = useStaticQuery(graphql`
    query {
      datoCmsBlog{
        slug
        titel
        untertitel
        hintergrundbild{
          alt
          fluid(maxWidth:1500){
            src
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
 
      }
      datoCmsHome{
        spainholidayBanner{
          title
          subtitle
          buttonLink
          buttonTitle
        }
      }
    }
  `)

  const { classes } = props
  return (
    <div className={classes.root}>
      <Header
        SEOtitle={data.datoCmsBlog.titel}
        SEOdescription={data.datoCmsBlog.untertitel}
        SEOimage={data.datoCmsBlog.hintergrundbild.fluid.src}
        SEOpathname={data.datoCmsBlog.slug}
      />
      <PageHeader
        leftHeader
        image={data.datoCmsBlog.hintergrundbild.fluid}
        title={data.datoCmsBlog.titel}
        subtitle={data.datoCmsBlog.untertitel}
        alt={data.datoCmsBlog.hintergrundbild.alt}
      />
      <BlogMain
        // textUnterBildAnzeigen={data.datoCmsBlog.textUnterBildAnzeigen}
      />
      <InstagramSection/>
      <SpainHolidaysCta
        title={data.datoCmsHome.spainholidayBanner[0].title}
        subtitle={data.datoCmsHome.spainholidayBanner[0].subtitle}
        buttonTitle={data.datoCmsHome.spainholidayBanner[0].buttonTitle}
        buttonLink={data.datoCmsHome.spainholidayBanner[0].buttonLink}
      />
      <Footer/>
    </div>
  )
}

export default withStyles(styles)(Blog)
